import React from 'react';
import { getStatusLabel } from '../constant/functions';
import { format } from 'date-fns';

const TimesheetViewModal = ({ isOpen, onClose, timesheet, employee }) => {
  if (!isOpen) return null;

  console.log('timesheet view: ', timesheet);
  const { label, textColor, bgColor } = getStatusLabel(timesheet?.status);

  const formattedCreatedDate = !Number.isNaN(new Date(timesheet?.createdDate).getTime())
    ? format(new Date(timesheet?.createdDate), 'dd-MM-yyyy HH:mm')
    : 'Invalid Date';

  const formattedDate = !Number.isNaN(new Date(timesheet?.date).getTime())
    ? format(new Date(timesheet?.date), 'dd-MM-yyyy')
    : 'Invalid Date';

  const convertMinutesToTimeFormat = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12; // Converts 0 to 12 for midnight
    const formattedMinutes = minutes.toString().padStart(2, '0'); // Adds leading zero if necessary

    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  const convertMinutesToHours = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const hoursText = hours === 1 ? '1 hour' : `${hours} hours`;
    const minutesText = minutes === 1 ? '1 minute' : `${minutes} minutes`;

    return `${hoursText} ${minutesText}`;
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
        <div className="bg-white flex flex-col w-[600px] p-10 rounded-lg shadow-lg justify-start items-start">
          <div className="flex flex-wrap justify-between w-full items-center">
            <h2 className="text-2xl font-bold text-black">Timesheet Details View</h2>
            <button
              type="button"
              onClick={() => onClose()}
              className="hover:bg-red1 text-black hover:text-white text-sm font-bold h-8 w-8 rounded-xl"
            >
              X
            </button>
          </div>
          <h1 className="text-sm text-black mt-5">
            <span className="font-bold">Date: </span>
            {formattedDate}
          </h1>
          <h1 className="text-sm text-black mt-2">
            <span className="font-bold">Start Time: </span>
            {convertMinutesToTimeFormat(timesheet?.startTime)}
          </h1>
          <h1 className="text-sm text-black mt-2">
            <span className="font-bold">End Time: </span>
            {convertMinutesToTimeFormat(timesheet?.endTime)}
          </h1>
          <h1 className="text-sm text-black mt-2">
            <span className="font-bold">Hours: </span>
            {convertMinutesToHours(timesheet?.hours)}
          </h1>
          <h1 className="text-sm text-black mt-2">
            <span className="font-bold">Hourly rate ({employee?.salaryDetails?.currency}): </span>
            {new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(timesheet?.hourlyRate)}
          </h1>
          <h1 className="text-sm text-black mt-2">
            <span className="font-bold">Amount ({employee?.salaryDetails?.currency}): </span>
            {new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(timesheet?.amount)}
          </h1>
          <h1 className="text-sm text-black mt-2 flex gap-x-2">
            <span className="font-bold">Status: </span>
            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
              <span className={`text-xs ${textColor}`}> {label}</span>
            </div>
          </h1>
        </div>
      </div>
    </>
  );
};

export default TimesheetViewModal;
