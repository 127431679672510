import axios from 'axios';
import { ToastBar } from '../components/toastbar';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_ALL_EMPLOYEES = 'FETCH_ALL_EMPLOYEES';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_SEARCH_EMPLOYEES = 'FETCH_SEARCH_EMPLOYEES';
export const FETCH_PENDING_LEAVES = 'FETCH_PENDING_LEAVES';
export const FETCH_APPROVER_MANAGERS = 'FETCH_APPROVER_MANAGERS';
export const FETCH_CURRENT_EMPLOYEE = 'FETCH_CURRENT_EMPLOYEE';
export const FETCH_PENDING_MEDICALS = 'FETCH_PENDING_MEDICALS';
export const FETCH_PENDING_TIMESHEETS = 'FETCH_PENDING_TIMESHEETS';

export const fetchCurrentEmployee = (data) => ({
  type: FETCH_CURRENT_EMPLOYEE,
  payload: data
});

export const fetchEmployeeSuccess = (data) => ({
  type: FETCH_EMPLOYEE_SUCCESS,
  payload: data
});

export const fetchAllEmployees = (data) => ({
  type: FETCH_ALL_EMPLOYEES,
  payload: data
});

export const fetchSearchEmployees = (data) => ({
  type: FETCH_SEARCH_EMPLOYEES,
  payload: data
});

export const fetchPendingLeaves = (data) => ({
  type: FETCH_PENDING_LEAVES,
  payload: data
})

export const fetchPendingMedicals = (data) => ({
  type: FETCH_PENDING_MEDICALS,
  payload: data
})

export const fetchPendingTimesheets = (data) => ({
  type: FETCH_PENDING_TIMESHEETS,
  payload: data
})

export const fetchApproverManagers = (data) => ({
  type: FETCH_APPROVER_MANAGERS,
  payload: data
})

export const addEmployee = (inputData, navigate, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/add-employee`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('add employee api res: ', response.data);

        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        //dispatch(fetchAllEmployees(response.data));
        resetForm();
        
        if (response.data && response.data?.employee?._id) {
          navigate(`/employees/${response.data?.employee?._id}`, { state: { isNewEmployee: true } })
        } else {
          console.log('New employee not found in the response');
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addBulkEmployee = (inputData, companyId) => {
  console.log('inputData bulk: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/add-bulk-employee`, { companyId, ...inputData }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editPersonalDetails = (inputData, setEdit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-personal-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({companyId: inputData.companyId}));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editEmploymentDetails = (inputData, setEdit) => {
  console.log(inputData.companyId)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-employment-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({companyId: inputData.companyId}));
        dispatch(getApprovers(inputData.departmentCode, inputData.companyId));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editSalaryDetails = (inputData, setEdit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-salary-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({companyId: inputData.companyId}));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editCredentialDetails = (inputData, setEdit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-credential-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        // dispatch(getAllEmployees());
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editApprovingDetails = (inputData, setEdit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-approving-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(getAllEmployees());
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getAllEmployees = (inputData) => {
  console.log(inputData)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-all-employees`, {
        params: inputData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('api res: ', response.data);
        dispatch(fetchAllEmployees(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getEmployeeById = (inputData, setEmployee) => {
  return async () => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-employee-byid`, {
        params: inputData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('getbyId res: ', response.data);
        setEmployee(response.data?.employee);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const addAllowance = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/add-allowance`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateAllowance = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/update-allowance`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteAllowance = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/delete-allowance`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addDeduction = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/add-deduction`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateDeduction = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/update-deduction`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        // dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteDeduction = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/delete-deduction`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        // dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addClaim = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/add-claim`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateClaim = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/update-claim`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        //dispatch(fetchAllEmployees(response.data));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteClaim = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/delete-claim`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addLeave = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/add-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addMedicalLeave = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/add-medical-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteMedicalLeave = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/delete-medical-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
        setIsConfirmationModalOpen(false);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
        setIsConfirmationModalOpen(false);
      }
    }
  };
};

export const updateMedicalLeave = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/update-medical-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const searchEmployees = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/search-employees`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('search-employees: ', response.data);
        dispatch(fetchSearchEmployees(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const searchHourlyEmployees = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/search-hourly-employees`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('search-employees: ', response.data);
        dispatch(fetchSearchEmployees(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getCurrentEmployee = (employeeId) => {
  console.log(employeeId)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-employee-byid`, {
        params: { employeeId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('getbyId res: ', response.data);
        dispatch(fetchCurrentEmployee(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getEmployee = (employeeId) => {
  console.log(employeeId)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-employee-byid`, {
        params: { employeeId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('getbyId res: ', response.data);
        dispatch(fetchEmployeeSuccess(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const applyLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/apply-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/cancel-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const applyMedicalLeave = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/apply-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editMedicalLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelMedicalLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/cancel-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const getPendingLeaves = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-pending-leaves`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingleaves res: ', response.data);
        dispatch(fetchPendingLeaves(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveLeave = (leaves, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/approve-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingLeaves(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectLeave = (leaves, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/reject-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingLeaves(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingLeavesByApprover = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/manager/get-pending-leaves-by-approver`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingleaves res: ', response.data);
        dispatch(fetchPendingLeaves(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveLeaveByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/approve-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingLeavesByApprover());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectLeaveByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/reject-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingLeavesByApprover());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingMedicalsByApprover = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/manager/get-pending-medicals-by-approver`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('res: ', response.data);
        dispatch(fetchPendingMedicals(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveMedicalByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/approve-medical`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingMedicalsByApprover());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectMedicalByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/reject-medical`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingMedicalsByApprover());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateLeave = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/update-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteLeave = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/delete-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
        setIsConfirmationModalOpen(false);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
        setIsConfirmationModalOpen(false);
      }
    }
  };
};

export const editEmployeeProfilePic = (values, closeModal, companyId) => {
  const inputData = values;

  console.log('input value for employee: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-profile-pic`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getAllEmployees(companyId));
        closeModal();
        ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateEmployeeWorkingHourDetails = (employeeId, companyId, values, setEdit) => {
  console.log(employeeId)
  const inputData = values;

  console.log('input value for employee: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-working-hours`, { employeeId, companyId, ...inputData }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getAllEmployees());
        setEdit(false);
        ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getApprovers = (departmentCode, companyId) => {
  console.log("departmentCode ", departmentCode)
  console.log("companyId ", companyId)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-approvers`, {
        params: { departmentCode, companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('approvers res: ', response.data);
        dispatch(fetchApproverManagers(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateAccessControl = (values) => {
  console.log('input values: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/update-access-control`, values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      console.log('api res: ', response.data);

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        //dispatch
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingMedicals = (companyId) => {
  console.log(companyId)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-pending-medicals`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('res: ', response.data);
        dispatch(fetchPendingMedicals(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveMedical = (medicals, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/approve-medical`, medicals, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingMedicals(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectMedical = (leaves, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/reject-medical`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingMedicals(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingTimesheetsByApprover = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/manager/get-pending-timesheets-by-approver`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingleaves res: ', response.data);
        dispatch(fetchPendingTimesheets(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveTimesheetByApprover = (timesheets) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/approve-timesheet`, timesheets, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingTimesheetsByApprover());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectTimesheetByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/reject-timesheet`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingTimesheetsByApprover());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingTimesheets = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-pending-timesheets`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingTimesheets res: ', response.data);
        dispatch(fetchPendingTimesheets(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveTimesheet = (timesheets, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/approve-timesheet`, timesheets, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingTimesheets(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectTimesheet = (timesheets, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/reject-timesheet`, timesheets, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingTimesheets(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};