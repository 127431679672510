import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyById } from '../../../actions/companyAction';
import SkeletonLoader from '../../../components/skeletonLoader';
import CompanyDetails from './companyDetails';
import BranchDetails from './branchDetails';
import DepartmentDetails from './departmentDetails';
import DesignationDetails from './designationDetails';
import { useLocation, useParams } from 'react-router-dom';
import LayoutWM from '../../../layout/layoutWM';

const Company = () => {
  const { id } = useParams();
  const location = useLocation();
  const isNewCompany = location.state?.isNewCompany;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const company = useSelector((state) => state.company?.company);
  console.log(id);
  useEffect(() => {
    if (id != null) {
      setLoading(true);
      dispatch(getCompanyById({ companyId: id }, setLoading));
    }
  }, [id]);

  return (
    <LayoutWM pageTitle={'Company Details'} page={'Settings'} subPage={'Company'}>
      <div className="flex flex-col justify-center items-start w-full">
        {loading ? (
          <SkeletonLoader length={8} />
        ) : (
          <div className="w-full space-y-4">
            <CompanyDetails company={company} isNewCompany={isNewCompany} />
            {/* <BranchDetails company={company} /> */}
            <DepartmentDetails company={company} />
            <DesignationDetails company={company} />
          </div>
        )}
      </div>
    </LayoutWM>
  );
};

export default Company;
