import { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../../components/dropdown';
import DatePicker from '../../../components/DatePicker';
import { ToastBar } from '../../../components/toastbar';
import { useFormik } from 'formik';
import { applyMedicalSchema } from '../../../validations/validationSchemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { format } from 'date-fns';
import { DocumentUpload, Edit2, Eye, MinusCirlce } from 'iconsax-react';
import { CancelLeaveModal } from './cancelLeaveModal';
import EditMedicalLeave from './editMedicalLeave';
import empty from '../../../assets/images/empty.png';
import { ViewDocumentModal } from './viewDocumentModal';
import { applyMedicalLeaveByUser, cancelMedicalLeaveByUser } from '../../../actions/userAction';
import { getCurrentEmployee } from '../../../actions/employeeAction';
import UserLayout from '../../../layout/userLayout';
import Compressor from 'compressorjs';

const UserApplyMedicalLeave = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingLeave, setEditingLeave] = useState(null);
  const [leaveToCancel, setLeaveToCancel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [medicalFile, setMedicalFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToView, setFileToView] = useState(null);

  const user = useSelector((state) => state?.employee?.user);

  useEffect(() => {
    dispatch(getCurrentEmployee(user._id));
  }, [dispatch]);

  const [sortConfig, setSortConfig] = useState({
    key: 'fullName',
    direction: 'ascending'
  });

  console.log('user: ', user);

  const formik = useFormik({
    initialValues: {
      medicalType: '',
      fromDate: '',
      toDate: '',
      medicalFile: null
    },
    validationSchema: applyMedicalSchema,
    onSubmit: (values) => {
      handleMedicalApplyLeave(values);
    }
  });

  useEffect(() => {
    if (formik.values.fromDate && formik.values.toDate) {
      calculateNumberOfDays(formik.values.fromDate, formik.values.toDate);
    }
  }, [formik.values.fromDate, formik.values.toDate]);

  const handleUploadFile = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    // Handle image file compression (JPEG, JPG, PNG)
    if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
      new Compressor(file, {
        quality: 0.6, // Adjust quality to compress more or less
        success(compressedBlob) {
          console.log('Compressed image size:', compressedBlob.size);

          // Check if the compressed file exceeds 2MB after compression
          if (compressedBlob.size > 2 * 1024 * 1024) {
            ToastBar.warning("Compressed image size exceeds 2MB");
            return;
          }

          // If within limits, set the file
          setMedicalFile(compressedBlob);
          formik.setFieldValue('medicalFile', compressedBlob);
        },
        error(err) {
          console.error('Compression error:', err);
          ToastBar.error('Failed to compress the image.');
        },
      });
    }
    // Handle PDF files
    else if (file.type === 'application/pdf') {
      if (file.size > 2 * 1024 * 1024) {
        ToastBar.warning("PDF file size exceeds 2MB");
        return;
      }
      setMedicalFile(file);
      formik.setFieldValue('medicalFile', file);
    }
    // Unsupported file type
    else {
      ToastBar.error("Unsupported file type. Please upload a JPEG, JPG, PNG, or PDF file.");
    }
  };

  const handleClearFile = () => {
    setMedicalFile(null);
    formik.setFieldValue('medicalFile', null);
    fileInputRef.current.value = '';
  };

  const handleView = (file) => {
    console.log(file);
    setFileToView(file.medicalFile);
    setIsModalOpen(true);
  };

  const closeViewModal = () => {
    setIsModalOpen(false);
    setFileToView(null);
  };

  const calculateNumberOfDays = (fromDate, toDate) => {
    if (!fromDate || !toDate) return;

    let currentDate = new Date(fromDate);
    const endDate = new Date(toDate);
    let dayCount = 0;

    // Get the list of public holiday dates as an array of startDates
    const publicHolidayDates = user?.company?.publicHolidays?.map(holiday => new Date(holiday.startDate).toDateString()) || [];

    // Loop through each date in the range
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();

      // Check if the current date is a weekend or a public holiday
      if (dayOfWeek !== 0 && dayOfWeek !== 6 && !publicHolidayDates.includes(currentDate.toDateString())) {
        dayCount++;
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    setNumberOfDays(dayCount);

    if (formik.values.medicalType && user) {
      const medical = user.medicalLeaveDetails.medicals.find(
        (medical) => medical.medicalType === formik.values.medicalType
      );
      if (medical && dayCount > medical.balanceDays) {
        ToastBar.warning('The number of days exceeds the balance days available.');
      }
    }
  };

  const handleMedicalApplyLeave = async (values) => {
    const { medicalType, fromDate, toDate } = values;

    const medical = user.medicalLeaveDetails.medicals.find((medical) => medical.medicalType === medicalType);

    if (!medical) {
      ToastBar.error('Please select a valid medical type.');
      return;
    }

    if (medical && numberOfDays > medical.balanceDays) {
      ToastBar.warning('The number of days exceeds the balance days available.');
      return;
    }

    const formData = new FormData();

    formData.append('medicalType', medicalType);
    formData.append('medicalCode', medical.medicalCode);
    formData.append('fromDate', fromDate);
    formData.append('toDate', toDate);
    formData.append('numberOfDays', numberOfDays);
    formData.append('status', 'Pending');
    formData.append('companyId', user?.company?._id);
    formData.append('employeeId', user?._id);

    if (medicalFile) {
      formData.append('file', medicalFile, `medicalFile_${user?._id}`);
    }

    const result = await dispatch(applyMedicalLeaveByUser(formData, user?._id));
    console.log(result);
    if (result.success) {
      setIsFormVisible(false);
      setMedicalFile(null);
      formik.setFieldValue('medicalFile', null);
      fileInputRef.current.value = '';
    }
  };

  const resetFormState = () => {
    formik.resetForm();
    setEditingLeave(null);
    setNumberOfDays(0);
  };

  const toggleFormVisibility = () => {
    resetFormState();
    setIsFormVisible(!isFormVisible);
  };

  const handleEditClick = (medical) => {
    resetFormState();
    setEditingLeave(medical);
    setIsFormVisible(true);
  };

  const handleCancelLeave = (medical) => {
    setLeaveToCancel(medical);
  };

  const confirmCancelLeave = () => {
    if (leaveToCancel) {
      const updatedLeaveToCancel = {
        ...leaveToCancel,
        companyId: user?.company._id,
        employeeId: user?._id
      };

      dispatch(cancelMedicalLeaveByUser(updatedLeaveToCancel, user?._id));
      setLeaveToCancel(null);
    }
  };

  const closeModal = () => {
    setLeaveToCancel(null);
  };

  const medicalLeaveOptions = user?.medicalLeaveDetails?.medicals.map((medical) => ({
    label: medical.medicalType,
    value: medical.medicalCode
  }));

  const sortedMedicalLeavesHistory = useMemo(() => {
    if (!user || !user.medicalLeaveDetails || !user.medicalLeaveDetails.medicalsHistory) {
      return [];
    }

    let sortableMedicals = [...user?.medicalLeaveDetails?.medicalsHistory];
    sortableMedicals.sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case 'medicalType':
          aValue = a.medicalType?.toLowerCase() || '';
          bValue = b.medicalType?.toLowerCase() || '';
          break;
        case 'medicalCode':
          aValue = a.medicalCode?.toLowerCase() || '';
          bValue = b.medicalCode?.toLowerCase() || '';
          break;
        case 'fromDate':
          aValue = new Date(a.fromDate).getTime();
          bValue = new Date(b.fromDate).getTime();
          break;
        case 'toDate':
          aValue = !Number.isNaN(new Date(a.toDate).getTime()) ? new Date(a.toDate).getTime() : 0;
          bValue = !Number.isNaN(new Date(b.toDate).getTime()) ? new Date(b.toDate).getTime() : 0;
          break;
        case 'numberOfDays':
          aValue = a.numberOfDays;
          bValue = b.numberOfDays;
          break;
        case 'appliedDate':
          aValue = new Date(a.appliedDate).getTime();
          bValue = new Date(b.appliedDate).getTime();
          break;
        case 'status':
          aValue = a.status?.toLowerCase() || '';
          bValue = b.status?.toLowerCase() || '';
          break;
        default:
          return 0;
      }

      if (sortConfig.direction === 'ascending') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    return sortableMedicals;
  }, [user, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'Approved':
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 'Pending':
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 'Cancelled':
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedMedicalLeavesHistory.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedMedicalLeavesHistory.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <UserLayout pageTitle={'Apply Medical Leave'} page={'Apply Medical Leave'} subPage={'Apply Medical Leave'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto">
        <div>
          {/* <h3 className="text-4xl font-semibold text-black pb-5">Employees</h3> */}
          <div className="flex justify-between">
            <div className="flex w-3/4 gap-x-4">
              <input
                type="text"
                placeholder="Department"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                name="department"
                value={user?.employmentDetails?.departmentCode}
                disabled
              />
              <input
                type="text"
                placeholder="Employee"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                name="department"
                value={user?.fullName}
                disabled
              />
            </div>
            <button
              onClick={toggleFormVisibility}
              disabled={!user || !medicalLeaveOptions?.length > 0}
              className={
                isFormVisible
                  ? 'h-12 w-36 font-bold rounded-xl border-2 bg-red1 text-white hover:bg-red1/90 disabled:bg-gray-400'
                  : 'h-12 w-36 bg-purple1 hover:bg-purple1/90 text-whiten font-bold rounded-xl disabled:bg-gray-400'
              }
            >
              {isFormVisible ? 'Cancel' : 'Apply Leave'}
            </button>
          </div>
          <div className="flex flex-wrap gap-3 mt-5">
            {user?.medicalLeaveDetails?.medicals?.map((medical, index) => {
              return (
                <div className="p-6 relative bg-white shadow-lg flex-grow basis-[350px] rounded-2xl" key={index}>
                  <div className="text-base text-black font-bold underline">{medical?.medicalType}</div>
                  <div className="text-sm font-normal text-black">
                    <span className="font-semibold">Leave Code:</span> {medical?.medicalCode}
                  </div>
                  <div className="text-sm font-normal text-black/40">
                    <span className="font-semibold">Total Days:</span> {medical?.totalDays}
                  </div>
                  <div className="text-sm font-normal text-black/40">
                    <span className="font-semibold">Balance:</span> {medical?.balanceDays}
                  </div>
                  <div className="text-sm font-normal text-black/40">
                    <span className="font-semibold">Taken:</span> {medical?.takenDays}
                  </div>
                </div>
              );
            })}
          </div>
          {/* Edit Leave Application Form */}
          {editingLeave && isFormVisible && (
            <EditMedicalLeave
              medical={editingLeave}
              onCancel={() => {
                resetFormState();
                setIsFormVisible(false);
              }}
              user={user}
              company={user?.company}
            />
          )}

          {/* Leave Application Form */}
          {user && medicalLeaveOptions?.length > 0
            ? !editingLeave &&
            isFormVisible &&
            user && (
              <div className="bg-white p-6 rounded-2xl shadow-lg mt-5">
                <h4 className="text-2xl font-bold mb-5 text-black">Apply Medical Leave</h4>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                    <div className="w-full lg:w-1/2">
                      <label className="mb-2.5 block text-black font-bold">Select Leave Type</label>
                      <Dropdown
                        options={medicalLeaveOptions || []}
                        value={medicalLeaveOptions?.find((option) => option.label === formik.values.medicalType)}
                        onChange={(option) => {
                          formik.setFieldValue('medicalType', option.label);
                          formik.setFieldTouched('medicalType', true, false);
                        }}
                        placeholder="Select Leave Type"
                        name="medicalType"
                        id="medicalType"
                      />
                      {formik.touched.medicalType && formik.errors.medicalType ? (
                        <div className="text-red-500 text-sm">{formik.errors.medicalType}</div>
                      ) : null}
                    </div>
                    <div className="w-full lg:w-1/2"></div>
                  </div>
                  <div className="mb-7 flex flex-col gap-6 lg:flex-row">
                    <div className="w-full lg:w-1/2">
                      <label className="mb-2.5 block text-black font-bold">From Date</label>
                      <DatePicker
                        value={formik.values.fromDate}
                        onChange={(date) => {
                          formik.setFieldValue('fromDate', date);
                          formik.setFieldTouched('fromDate', true, false);
                        }}
                      />
                      {formik.touched.fromDate && formik.errors.fromDate ? (
                        <div className="text-red-500 text-sm">{formik.errors.fromDate}</div>
                      ) : null}
                    </div>

                    <div className="w-full lg:w-1/2">
                      <label className="mb-2.5 block text-black font-bold">To Date</label>
                      <DatePicker
                        value={formik.values.toDate}
                        onChange={(date) => {
                          formik.setFieldValue('toDate', date);
                          formik.setFieldTouched('toDate', true, false);
                        }}
                      />
                      {formik.touched.toDate && formik.errors.toDate ? (
                        <div className="text-red-500 text-sm">{formik.errors.toDate}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="mb-6 mt-6">
                    <div className="block text-base text-black font-bold">
                      Number of Days: <span className="font-normal">{numberOfDays}</span>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 mt-5">
                    <label className="mb-2.5 block text-black font-bold">Upload Document (Optional)</label>
                    <div className="relative flex items-center">
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept=".jpeg, .jpg, .png, .pdf"
                        onChange={handleUploadFile}
                        style={{ display: 'none' }}
                      />
                      <input
                        type="text"
                        placeholder="No file chosen"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 pr-12 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                        value={medicalFile ? medicalFile.name : ''}
                        readOnly
                        onClick={() => fileInputRef.current.click()}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {medicalFile ? (
                          <MinusCirlce
                            onClick={handleClearFile}
                            color="#C84040"
                            variant="Bold"
                            size={20}
                            className="cursor-pointer"
                            title="Clear"
                          />
                        ) : (
                          <DocumentUpload
                            onClick={() => fileInputRef.current.click()}
                            color="#555555"
                            variant="Bold"
                            size={20}
                            className="cursor-pointer"
                            title="Upload file"
                          />
                        )}
                      </div>
                    </div>
                    {formik.touched.medicalFile && formik.errors.medicalFile ? (
                      <div className="text-red-500 text-sm">{formik.errors.medicalFile}</div>
                    ) : null}
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-gray-500 hover:bg-gray-700 text-white font-semibold rounded-md px-4 py-2 mr-2"
                      onClick={toggleFormVisibility}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-lg px-4 py-2"
                    >
                      Submit Leave
                    </button>
                  </div>
                </form>
              </div>
            )
            : user && (
              <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                <img src={empty} alt="empty data" />
                <div className="mt-5 text-purple1 font-bold text-xl">No Leave For This Employee</div>
              </div>
            )}
          {currentEntries.length > 0 ? (
            <div>
              <div className="flex justify-end items-center  my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
                    <th>Full Name</th>
                    <th onClick={() => handleSort('medicalType')} className="cursor-pointer">
                      Medical Type
                      <FontAwesomeIcon icon={getSortIcon('medicalType')} size="sm" className="ml-1" />
                    </th>
                    {/* <th onClick={() => handleSort('medicalCode')} className="cursor-pointer">
                      Medical Code
                      <FontAwesomeIcon icon={getSortIcon('medicalCode')} size="sm" className="ml-1" />
                    </th> */}
                    <th onClick={() => handleSort('fromDate')} className="cursor-pointer">
                      From Date <FontAwesomeIcon icon={getSortIcon('fromDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('toDate')} className="cursor-pointer">
                      To Date <FontAwesomeIcon icon={getSortIcon('toDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('numberOfDays')} className="cursor-pointer">
                      No of Days <FontAwesomeIcon icon={getSortIcon('numberOfDays')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('appliedDate')} className="cursor-pointer">
                      Applied Date <FontAwesomeIcon icon={getSortIcon('appliedDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                      Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
                    </th>
                    <th className="rounded-e-xl">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((medical, index) => {
                    const formattedFromDate = !Number.isNaN(new Date(medical?.fromDate).getTime())
                      ? format(new Date(medical?.fromDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedToDate = !Number.isNaN(new Date(medical?.toDate).getTime())
                      ? format(new Date(medical?.toDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedAppliedDate = !Number.isNaN(new Date(medical?.appliedDate).getTime())
                      ? format(new Date(medical?.appliedDate), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date';

                    const { label, textColor, bgColor } = getStatusLabel(medical?.status);

                    const isValidToCancel =
                      new Date() <= new Date(new Date(medical?.fromDate).getTime() + 24 * 60 * 60 * 1000);

                    return (
                      <tr className="h-20 text-sm" key={index}>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={user?.avatar ? user?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center">{user?.fullName}</td>
                        {/* <td className="h-20 text-center" title={user?.fullName}>
                          {user?.fullName?.slice(0, 12)}
                          {user?.fullName?.length > 12 ? '...' : ''}
                        </td> */}
                        <td className="h-20 text-center">{medical?.medicalType}</td>
                        {/* <td className="h-20 text-center">{medical?.medicalCode}</td> */}
                        <td className="h-20 text-center">{formattedFromDate}</td>
                        <td className="h-20 text-center">{formattedToDate}</td>
                        <td className="h-20 text-center">{medical?.numberOfDays}</td>
                        <td className="h-20 text-center">{formattedAppliedDate}</td>
                        <td className="h-20 w-26">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-end gap-x-2 items-center">
                            {medical?.medicalFile && (
                              <div
                                className="flex justify-center items-center w-8 h-8 p-1.5 rounded-md hover:bg-blue-400 shadow-md"
                                onClick={() => handleView(medical)}
                                title="Edit"
                              >
                                <Eye color="#000000" variant="Bold" size={20} className="cursor-pointer" />
                              </div>
                            )}
                            {(medical?.status === 'Pending' || medical?.status === 'Rejected') && (
                              <div
                                className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-green-400 shadow-md"
                                onClick={() => handleEditClick(medical)}
                                title="Edit"
                              >
                                <Edit2 color="#000000" variant="Bold" size={20} className="cursor-pointer" />
                              </div>
                            )}
                            {(medical?.status === 'Pending' || (medical?.status === 'Approved' && isValidToCancel)) && (
                              <div
                                className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-100 shadow-md"
                                onClick={() => handleCancelLeave(medical)}
                                title="Cancel"
                              >
                                <MinusCirlce color="#C84040" variant="Bold" size={20} className="cursor-pointer" />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            user &&
            medicalLeaveOptions?.length > 0 && (
              <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                <img src={empty} alt="empty data" />
                <div className="mt-5 text-purple1 font-bold text-xl">No Leave History</div>
              </div>
            )
          )}
          {leaveToCancel && <CancelLeaveModal onCancel={closeModal} onConfirm={confirmCancelLeave} />}
        </div>
        {currentEntries.length > 0 && (
          <div className="flex">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedMedicalLeavesHistory.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${pageNumber === currentPage
                          ? 'bg-purple1 text-white rounded-xl font-bold'
                          : 'text-black bg-oppty-green-5'
                          }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && <ViewDocumentModal fileToView={fileToView} closeViewModal={closeViewModal} />}
    </UserLayout>
  );
};

export default UserApplyMedicalLeave;
